var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      [_c("Jumbotron", { attrs: { title: "Zahlungseinstellungen" } })],
      1
    ),
    !_vm.hasActiveSubscription
      ? _c("div", [
          _vm._v("Sie sind noch nicht für Zahlungseingänge freigeschaltet")
        ])
      : _vm._e(),
    _vm.hasActiveSubscription
      ? _c("div", [
          _vm.hasStripeAccount
            ? _c(
                "div",
                [
                  _vm._v(
                    " Ihre Stripe Account Id: " +
                      _vm._s(_vm.data.stripeAccountId) +
                      " "
                  ),
                  !!_vm.stripeAccountLinkedAt
                    ? _c(
                        "CButton",
                        { on: { click: _vm.goToStripeAccountLink } },
                        [_vm._v("Einrichtung fortsetzen")]
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          !_vm.hasStripeAccount
            ? _c(
                "div",
                [
                  _c(
                    "CButton",
                    {
                      attrs: { color: "primary" },
                      on: { click: _vm.createStripeAccount }
                    },
                    [_vm._v("Jetzt Account bei Stripe einrichten")]
                  )
                ],
                1
              )
            : _vm._e()
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }